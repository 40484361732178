nav.desktopNav {
  position: sticky;
  padding: 0 15px;
  top: 0;
  width: 100%;
  background-color: $olive;
  color: $cream;
  display: flex;
  align-items: center;
  height: 8vh;
  ul {
    display: flex;
    flex-grow: 2;
    justify-content: flex-end;
    li.home {
      flex-grow: 2;
    }
    li {
      padding: 10px;
      a {
        transition: 0.3s linear all;
        &:hover {
          color: $caramel;
        }
      }
    }
  }
}

nav.mobileNav {
  display: flex;
  position: fixed;
  height: 100vh;
  align-items: center;
  background-color: $olive;
  color: $cream;
  width: 60vw;
  left: -90vw;
  justify-content: center;
  transition: 0.3s linear all;

  li {
    font-size: 4rem;
    margin: 30px 0;
    a {
        transition: 0.3s linear all;
    
        &:hover {
          color: $caramel;
        }
      }
  }
}

nav.mobileNav.open {
  left: 0vw;
}

.menuButton {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  cursor: pointer;
  transition: all 0.3s linear;
  z-index: 10;
}

.menuBurger {
  width: 50px;
  height: 6px;
  background: $darkOlive;
  border-radius: 5px;
  transition: 0.3s linear all;

}

.menuBurger::before, .menuBurger::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 6px;
  background: $darkOlive;
  border-radius: 5px;
  transition: linear 0.3s all;
}


.menuBurger::before {
  transform: translateY(-16px);
}

.menuBurger::after {
  transform: translateY(16px);
}

// animate menu button
.menuButton.open .menuBurger {
  transform: translateX(-50px);
  background: transparent;
}

.menuButton.open .menuBurger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menuButton.open .menuBurger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
//end aminate menu button
