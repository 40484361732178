@media (max-width: 900px) {
  ul.projectList li:nth-child(odd) {
    flex-direction: column-reverse;
    img {
      margin-top: 15px;
    }
  }

  ul.projectList li:nth-child(even) {
    flex-direction: column-reverse;
    img {
        margin-top: 15px;
      }
  }

  .aboutMe {
    flex-direction: column-reverse;
  }

  .contactBox {
    flex-direction: column;
  }

  
}


@media (max-width: 600px) {
  .skillset {
    li {
      flex-basis: 50%;
    }
  }

  nav.mobileNav {
    width: 70vw;
    li {
      font-size: 2rem;
      margin: 20px 0;
      a {
        font-size: 2rem;
        margin: 0;
      }
    }
  }
}


@media (max-width: 400px) {
  .skillsUsed {
      flex-direction: column;
  }

  form .nameEmail {
    flex-direction: column;
    input {
      width: 100%;
    }

    input[type=email] {
    margin-top: 10px;
    margin-left: 0;
  }
  }
  

}
