//skills styling
.skills {
  min-height: 100vh;
  display: flex;
  align-items: center;
  
  ul {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    p {
      padding: 10px 0;
      font-weight: 300;
      text-align: center;
    }

    i {
      flex-wrap: wrap;
      padding: 10px;
      font-size: 8rem;
    }
  }
}

.skillset {
  li {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: 30%;
  }
}
//skills styling end